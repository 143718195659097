export function convertData(parsedJSON) {

    let questions = {};

    for(const question of parsedJSON.questions) {
        let choices = {};
        for (const choice of question.choices) {
            choices[choice.choiceID] = {
                text: choice.text,
                shortText: choice.shortText,
                multiplier: choice.multiplier,
                choiceID: choice.choiceID
            };
        }
        questions[question.questionID] = {
            text: question.text,
            shortText: question.shortText,
            description: question.description,
            type: question.type,
            number: question.number,
            correctChoiceID: question.correctChoiceID,
            questionID: question.questionID,
            choices: choices
        }
    }

    return {
        title : parsedJSON.title,
        instructions: parsedJSON.instructions,
        dueDate : parsedJSON.dueDate,
        questions : questions
    };
}

export function convertResultsData(parsedJSON) {

    let entries = [];
    let users = [];

    for(const userForm of parsedJSON.userForms) {
        if(userForm.selections) {
            users.push(userForm.userID);
            let selections = {};
            for (const selection of userForm.selections) {
                if (selections[selection.questionID] == null) {
                    selections[selection.questionID] = {};
                }
                selections[selection.questionID][selection.choiceID] = {
                    choiceID: selection.choiceID,
                    betAmount: selection.betAmount,
                    resolvedState: selection.resolvedState
                };
            }
            entries.push({
                userID: userForm.userID,
                potentialWinnings: userForm.potentialWinnings,
                actualWinnings: userForm.actualWinnings,
                selections: selections
            });
        }
    }

    return {
        users : users,
        entries: entries
    };
}