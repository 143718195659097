import React, {Component} from 'react';
import moment from "moment/moment";

class EditForm extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            formObject: {
                ...this.props.formObject
            },
            formError: null
        };
    }

    handleChange(e) {
        let value = e.target.value;
        // Convert input date string to JS Date object
        if(e.target.type === "date") {
            value = new Date(moment(value, "YYYY-MM-DD"));
        }
        this.validateForm();
        this.setState({formObject : {
                ...this.state.formObject,
                [e.target.id]: value
            }});
    };

    validateForm() {
        const isValid = this.form.current.checkValidity();
        this.setState({
            formError: !isValid ? "Please fix invalid fields." : null
        });
        return isValid;
    }

    submit() {
        if(this.validateForm()){
            this.props.handleSubmit(this.state.formObject);
        }
    };

    handleDelete() {
        const {handleDelete} = this.props;
        if(window.confirm("Are you sure you want to delete?")) {
            handleDelete();
        }
    }

    render() {
        const {children, handleSubmit, handleDelete, handleCancel} = this.props;
        const fieldsWithProps = React.Children.map(children, child =>
            React.cloneElement(child, {
                formObject: this.state.formObject,
                handleChange: this.handleChange.bind(this)
            })
        );
        return <div>
            <form ref={this.form}>
                {fieldsWithProps}
            </form>
            <p className="red-text">{this.state.formError}</p>
            <div className="clearfix">
                {handleDelete ?
                    <button className="btn left" onClick={this.handleDelete.bind(this)}>Delete</button> : null}
                {handleCancel ?
                    <button className="btn right" onClick={handleCancel}>Cancel</button> : null}
                {handleSubmit ?
                    <button className="btn right"
                            style={{marginRight: "5px"}}
                            onClick={this.submit.bind(this)}
                            disabled={this.state.formError !== null}>Submit</button> : null}
            </div>
        </div>;
    }
}

export default EditForm;