import React, { Component } from 'react'

class RadioQuestion extends Component {

    handleInput = (e) => {
        const {question, selectionMap, handleChange} = this.props;
        const oldSelection = selectionMap && Object.keys(selectionMap).length ?
            Object.values(selectionMap)[0] : {choiceID : -1, betAmount : 10};
        let newSelection;

        if(e.target.type === "radio") {
            newSelection = {
                [e.target.value] : {
                    choiceID: parseInt(e.target.value),
                    betAmount: oldSelection.betAmount
                }
            }
        } else {
            const amount = parseInt(e.target.value);
            newSelection = {
                [oldSelection.choiceID] : {
                    choiceID: oldSelection.choiceID,
                    betAmount: isNaN(amount) ? "" : amount
                }
            }
        }
        handleChange(question.questionID, newSelection);
    };

    render () {
        const {question, selectionMap, disabled} = this.props;

        //Check array for a selection otherwise set defaults
        const selection = selectionMap && Object.keys(selectionMap).length ?
            Object.values(selectionMap)[0] : {choiceID : -1, betAmount : 10};

        const choices = [];
        let potential = 0;
        for(const choice of Object.values(question.choices)) {
            const choiceID = choice.choiceID;
            const key = "choice-"+choiceID;
            if (choiceID === selection.choiceID) {
                potential = selection.betAmount * choice.multiplier;
                potential = Math.round(potential*100)/100;
            }
            choices.push(
                <p key={key}>
                    <label htmlFor={key} className="radio radio-label">
                        <input type="radio" className="with-gap" name={"question-"+question.questionID}
                               value={choiceID} id={key} checked={choiceID === selection.choiceID}
                               onChange={this.handleInput} disabled={disabled}
                        />
                        <span>{choice.text}</span>
                    </label>
                </p>)
        }

        return (
            <tr className="question">
                <td className="questionNumber">{question.number}.</td>
                <td>
                    <div className="text">{question.text}</div>
                    <div className="description">{question.description}</div>
                    <div className="answers">
                        {choices}
                        <div className="bet">
                            <label className="betLabel" htmlFor={"question-"+question.questionID+"-betamount"}>
                                Bet amount:
                                <input type="number" className="browser-default" name={"question-"+question.questionID+"-betamount"}
                                       id={"question-"+question.questionID+"-betamount"} min="5"
                                       max="30" step="1" value={selection.betAmount} required
                                       onChange={this.handleInput} disabled={disabled}
                                />
                                    <span className="potential"
                                          id={"question-"+question.questionID+"-betamount-potential"}>(Potential: ${potential})</span>
                            </label>
                        </div>

                    </div>
                </td>
            </tr>
        )
    }

}

export default RadioQuestion;