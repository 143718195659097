const initState = {
	saveError: null,
	saveSuccess: null
};

const userSettingsReducer = (state = initState, action) => {
	switch(action.type){
		case 'SAVE_USER_SETTINGS_SUCCESS':
			console.log('save user settings success');
			return {
				...state,
				saveError: null,
				saveSuccess: "User settings saved successfully"
			};

		case 'SAVE_USER_SETTINGS_ERROR':
			console.log('save user settings error');
			return {
				...state,
				saveError: "Failed to save user settings",
				saveSuccess: null
			};

		default:
			return state
	}
};

export default userSettingsReducer;