import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Bar } from 'react-chartjs-2';
import Loading from "../layout/Loading";


class Chart extends Component {

    componentDidMount() {
        let {match} = this.props;
        document.title = `${match.params.shortName} Chart`;
    }

    render() {
        const {contest, users, entries} = this.props;
        let chartData = null;
        let chartOptions = null;
        if(contest && users && entries) {
            let labels = [];
            let currentScores = [];
            let potentialScores = [];
            let datas = [];

            let entriesArray = Object.values(entries).sort(function(a,b) {
                return b.potentialWinnings - a.potentialWinnings;
            });

            entriesArray.forEach( entry => {

                const userProfile = users[entry.userID];
                if (entry && userProfile) {
                    const name = userProfile.name + (entry.paid ? " ($)" : "");
                    const current = entry.actualWinnings || 0;
                    const potential = entry.potentialWinnings || 0;
                    labels.push(name);
                    currentScores.push(current);
                    potentialScores.push(potential - current);
                    datas[name] = {
                        "current":current,
                        "potential": potential
                    };
                }

            });

            chartData = {
                labels: labels,

                datasets: [
                    {
                        label: 'Current Score',
                        backgroundColor: "rgba(0,200,0,0.7)",
                        borderColor: "rgba(0,180,0,0.8)",
                        hoverBackgroundFill: "rgba(0,180,0,1)",
                        hoverBackgroundStroke: "rgba(0,200,0,1)",
                        data: currentScores
                    },
                    {
                        label: 'Potential Score',
                        backgroundColor: "rgba(151,187,205,0.5)",
                        borderColor: "rgba(151,187,205,0.8)",
                        hoverBackgroundFill: "rgba(151,187,205,0.75)",
                        hoverBackgroundStroke: "rgba(151,187,205,1)",
                        data: potentialScores
                    },
                ]
            };

            chartOptions = {
                tooltips: {
                    mode:'label',
                    callbacks: {
                        label: function(label,data){
                            var userdata = label ? datas[label.xLabel] : null;
                            if(userdata) {
                                if (userdata.current === label.yLabel) {
                                    return "Current Winnings: " + userdata.current;
                                } else {
                                    return "Potential Winnings: " + userdata.potential;
                                }

                            } else {
                                return "Error fetching label";
                            }

                        },
                    }
                },
                scales:{
                    yAxes:[{
                        stacked: true,
                        ticks:{
                            beginAtZero:true
                        }
                    }],
                    xAxes:[
                        {
                            stacked: true,
                            ticks: {
                                stepSize: 1,
                                min: 0,
                                autoSkip:false
                            }
                        }
                    ]


                }
            };


        }

        const notPastDue = contest && contest.dueDate && new Date() < contest.dueDate.toDate();
        return (

            <div className="container">
                <div className="row entry-form-container">
                    <div className="col s12 m12 l10 offset-l1">
                    {(chartData && chartOptions) ?
                        <Bar data={chartData} options={chartOptions}/> :
                        notPastDue ?
                            <h6 className="center-align">Check back once the contest has started!</h6> :
                            <Loading/>
                        }
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        contest: state.firestore.data.contests && state.firestore.data.contests[ownProps.match.params.shortName],
        entries: state.firestore.data['contests/' + ownProps.match.params.shortName + '/entries'],
        users: state.firestore.data.users,
        auth: state.firebase.auth
    }
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        let collections = [];
        collections.push({
            collection: 'contests',
            doc: props.match.params.shortName,
        });
        collections.push({
            collection: 'users',
        });
        collections.push({
            collection: 'contests/' + props.match.params.shortName + '/entries',
        });
        return collections})
)(Chart)