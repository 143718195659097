import React, {Component} from 'react';
import {connect} from "react-redux";
import EditForm from "../../layout/EditForm";
import {importContestResults} from "../../../store/actions/adminActions";
import {convertResultsData} from "../../../utils/contest";

class ImportResultsForm extends Component {


    printError(error, explicit) {
        console.log(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`);
    }

    handleSubmit(contestFields) {
        const {importContestResults, handleDone} = this.props;
        //try {
            const parsed = JSON.parse(contestFields.json);
            const newContest = convertResultsData(parsed);
            importContestResults(contestFields.shortName,newContest);
            handleDone();
        /*} catch (e) {
            if (e instanceof SyntaxError) {
                this.printError(e, true);
            } else {
                this.printError(e, false);
            }
            alert("Error in JSON. Check console.");
        }*/
    };

    handleCancel() {
        const {handleDone} = this.props;
        handleDone();
    }

    render() {
        return <EditForm
            formObject={{json:"",shortName:""}}
            handleSubmit={this.handleSubmit.bind(this)}
            handleCancel={this.handleCancel.bind(this)}>
            <EditFormFields/>
        </EditForm>;
    }
}

const EditFormFields = (props) => {
    return <React.Fragment>
        <div>
            <label htmlFor="json">JSON</label>
            <textarea id='json' required
                      value={props.formObject.json}
                      onChange={props.handleChange}/>
        </div>
        <div>
            <label htmlFor="shortName">Short Name</label>
            <input type="text" id='shortName' required
                   value={props.formObject.shortName}
                   onChange={props.handleChange}/>
        </div>
    </React.Fragment>
};

const mapDispatchToProps = (dispatch) => {
    return {
        importContestResults: (shortName, contest) => dispatch(importContestResults(shortName, contest)),
    }
};

export default connect(null, mapDispatchToProps)(ImportResultsForm);