import React, {Component} from 'react';

export default class Instructions extends Component {
    render() {
        const {handleDone} = this.props;
        return <div className="row">
            <p className="flow-text"> The objective is simple - turn your money into the most profit and you win! Here are the fine details.</p>
            <ul className="browser-default">
                <li>Buy-in is $5 and can be sent to jason.bosko@gmail.com, or paid in person. Second place will take home $20, and first place will take home the rest! </li>
                <li>You get $100 in fake money to spend.  You must spend all $100, and you must bet on each prop.</li>
                <li>The majority of the props have two choices of which you must pick one.  You can bet a maximum of $30 and a minimum of $5 on one of these props.</li>
                <li>A few props will have many answers. These props offer much larger payouts, but are harder to get right.  You can pick multiple choices for these in order to spread your risk around.  You can only bet a maximum of $5 on each choice though.</li>
                <li>The odds for each answer are taken straight from an online betting website.  The odds are created such that the more likely answers have smaller payouts.
                    <ul className="browser-default">
                        <li>For odds with a negative sign, it means you must bet that amount in order to win $100.  For example, -125 means that a bet of $125 would win $100, so using that ratio, you would multiply your bet by (100/125=0.8) to figure out your profit if you win.</li>
                        <li>For odds with a positive sign, it means a bet of $100 would win you that amount of profit.  For example, +125 means a bet of $100 would win $125, so using that ratio, you would multiply your bet by (125/100=1.25) to figure out your profit if you win.</li>
                    </ul>
                </li>
            </ul>
            <button className="btn right" onClick={handleDone}>Done</button>
        </div>;
    }
}