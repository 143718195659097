import React, { Component } from 'react'

class CheckboxQuestion extends Component {

    handleInput = (e) => {
        const {question, selectionMap, handleChange} = this.props;
        let newSelections;
        let choiceID;

        if(e.target.type === "checkbox") {
            choiceID = e.target.value;
            if (e.target.checked) {
                //a new selection was made, we need to add it to the existing selection map
                newSelections = {
                    ...selectionMap,
                    [choiceID] : {
                        choiceID: parseInt(e.target.value),
                        betAmount: 5
                    }
                }
            }
            else {
                //an existing selection was unchecked, we need to remove it from the existing selection map
                newSelections = {};
                for (const existingID of Object.keys(selectionMap)) {
                    if (existingID !== choiceID) {
                        newSelections[existingID] = selectionMap[existingID];
                    }
                }
            }
        } else {
            //an existing selection had its bet amount changed, just update the existing selection
            choiceID = e.target.id.split("-")[3];
            const amount = parseInt(e.target.value);
            newSelections = {
                ...selectionMap,
                [choiceID] : {
                    choiceID: choiceID,
                    betAmount: isNaN(amount) ? "" : amount
                }
            }
        }
        handleChange(question.questionID, newSelections);
    };

    render () {
        const {question, selectionMap, disabled} = this.props;

        const choices = [];
        for(const [choiceID, choice] of Object.entries(question.choices)) {
            const key = "choice-"+choiceID;
            const checked = !!(selectionMap && selectionMap[choiceID]);
            let potential = 0;
            if (checked) {
                const selection = selectionMap[choiceID];
                potential = selection.betAmount * choice.multiplier;
            }
            choices.push(
                <div className="checkbox" key={key}>
                    <div className="col-sm-4">
                        <label htmlFor={key}>
                            <input type="checkbox" name={"question-"+question.questionID} id={key} value={choiceID}
                                   disabled={disabled} checked={checked} onChange={this.handleInput}/>
                            <span>{choice.text}</span>
                        </label>
                    </div>
                    {checked ?
                        <div className="col-sm-4">
                            <label className="betLabel" htmlFor={"question-"+question.questionID+"-choice-"+choiceID+"-betamount"}>
                                Bet amount:
                                <input type="number" className="browser-default" disabled={disabled} required
                                       name={"question-"+question.questionID+"-choice-"+choiceID+"-betamount"}
                                       id={"question-"+question.questionID+"-choice-"+choiceID+"-betamount"} min="1" max="5" step="1"
                                       value={selectionMap && selectionMap[choiceID] ? selectionMap[choiceID].betAmount : "" }
                                       onChange={this.handleInput}
                                />
                                <span className="potential" id={"question-"+question.questionID+"-choice-"+choiceID+"-betamount-potential"}>(Potential: ${potential})</span>
                            </label>
                        </div>
                        : null
                    }

                </div>)
        }

        return (
            <tr className="question">
                <td className="questionNumber">{question.number}.</td>
                <td>
                    <div className="text">{question.text}</div>
                    <div className="description">{question.description}</div>
                    <div className="answers">
                        {choices}
                    </div>
                </td>
            </tr>
        )
    }

}

export default CheckboxQuestion;