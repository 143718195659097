import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Replace this with your own config details
var config = {
    apiKey: "AIzaSyCS5xSxdPH3EPzdMGztAqmJ1rQlFj7a2cA",
    authDomain: "superbowl-prop-bets.firebaseapp.com",
    databaseURL: "https://superbowl-prop-bets.firebaseio.com",
    projectId: "superbowl-prop-bets",
    storageBucket: "superbowl-prop-bets.appspot.com",
    messagingSenderId: "509095852892"
};
firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase