import firebase from 'firebase/app'

// returns a float
export const calculatePotentialWinnings = (selections, questions) => {
	// Calculate potential winnings
	let potentialWinnings = 0;
	for (const questionID of Object.keys(selections)) {
		const choices = selections[questionID];
		let maxPotentialForChoice = 0;
		if (questions[questionID].correctChoiceID && questions[questionID].correctChoiceID !== -1) { //not sure if correctChoiceID will actually exist as a fake value when the contest is entered
			if (choices[questions[questionID].correctChoiceID]) {
				const choice = choices[questions[questionID].correctChoiceID];
				maxPotentialForChoice += Math.round(choice.betAmount * questions[questionID].choices[questions[questionID].correctChoiceID].multiplier * 100)/100;
				potentialWinnings += maxPotentialForChoice;
				continue;
			}
		} else {
			for (const choiceID of Object.keys(choices)) {
				const choice = choices[choiceID];
				let choicePotential = Math.round(choice.betAmount * questions[questionID].choices[choiceID].multiplier * 100)/100;
				if (choicePotential > maxPotentialForChoice) {
					maxPotentialForChoice = choicePotential;
				}
			}
			potentialWinnings += maxPotentialForChoice;
		}
	}
	return potentialWinnings
}

// returns a float
export const calculateActualWinnings = (selections, questions) => {
	// Calculate potential winnings
	let actualWinnings = 0;
	for (const questionID of Object.keys(selections)) {
		const choices = selections[questionID];
		if (questions[questionID].correctChoiceID && questions[questionID].correctChoiceID !== -1) { //not sure if correctChoiceID will actually exist as a fake value when the contest is entered
			if (choices[questions[questionID].correctChoiceID]) {
				const choice = choices[questions[questionID].correctChoiceID];
				actualWinnings += Math.round(choice.betAmount * questions[questionID].choices[questions[questionID].correctChoiceID].multiplier * 100)/100;
			}
		}
	}
	return actualWinnings
}

export const saveCorrectChoices = function (contestShortName, choices, entries, questions) {
	return (dispatch) => {
		const firestore = firebase.firestore();
		const contestDoc = firestore.collection('contests').doc(contestShortName);

		let questionsCopy = JSON.parse(JSON.stringify(questions));

		//TODO: This should be doing it in one go (or at the very least as a batch).
		Object.keys(choices).forEach(questionID => {
			const key = "questions."+questionID+".correctChoiceID";
			const updateParams = {};
			updateParams[key] = choices[questionID];
			contestDoc.update(updateParams);
			questionsCopy[questionID].correctChoiceID = choices[questionID];
		});

		let entriesCopy = JSON.parse(JSON.stringify(entries));

		// set resolvedState for every entry's choices
		const batch = firestore.batch();
		Object.keys(entries).forEach(userID => {
			const entryDoc = firestore.collection('contests').doc(contestShortName).collection('entries').doc(userID);
			Object.keys(choices).forEach(questionID => {
				Object.keys(entries[userID].selections[questionID]).forEach(choiceID => {
					// update the resolvedState of each entry
					let updateParams = {};
					updateParams.selections = {};
					updateParams.selections[questionID] = {};
					updateParams.selections[questionID][choiceID] = {};
					updateParams.selections[questionID][choiceID].resolvedState = (choices[questionID] === '-1') ? 0 : ((choiceID === choices[questionID]) ? 1 : 2);
					batch.set(entryDoc, updateParams, { merge: true});
					
					//also set entry so we can update actual/potential winnings
					entriesCopy[userID].selections[questionID][choiceID].resolvedState = (choiceID === choices[questionID]) ? 1 : 2;
				})				
			})
		});

		// now that resolvedState is set, calculate potentialWinnings and actualWinnings
		Object.keys(entriesCopy).forEach(userID => {
			const entryDoc = firestore.collection('contests').doc(contestShortName).collection('entries').doc(userID);
			batch.set(entryDoc, 
				{ 
					potentialWinnings: parseFloat(calculatePotentialWinnings(entriesCopy[userID].selections, questionsCopy).toFixed(2)),
					actualWinnings: parseFloat(calculateActualWinnings(entriesCopy[userID].selections, questionsCopy).toFixed(2))
				},
				{ merge: true}
			);
		});

		batch.commit()

		dispatch({ type: 'SAVE_CORRECT_CHOICES_SUCCESS' });
	}
}

export const saveContestEntry = (contestShortName, userID, entry, questions) => {
	return (dispatch) => {
		const firestore = firebase.firestore();
		const batch = firestore.batch();

        const entryDoc = firestore.collection('contests').doc(contestShortName).collection('entries').doc(userID);
		entry.userID = userID;
		
		
		entry.potentialWinnings = parseFloat(calculatePotentialWinnings(entry.selections, questions).toFixed(2));
		entry.actualWinnings = 0;
		batch.set(entryDoc, entry);

		const contestDoc = firestore.collection('contests').doc(contestShortName);
		batch.set(contestDoc, {
			entryUserIDs: firebase.firestore.FieldValue.arrayUnion(userID)
		}, { merge: true});

        batch.commit().then( () => {
            dispatch({ type: 'SAVE_CONTEST_ENTRY_SUCCESS' });
		}).catch( (error) => {
            dispatch({ type: 'SAVE_CONTEST_ENTRY_ERROR' }, error);
		});
	}
}