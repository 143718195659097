import React, {Component} from 'react';
import {connect} from "react-redux";
import EditForm from "../layout/EditForm";
import { saveUserSettings } from '../../store/actions/userSettingsActions'

class UserSettingsForm extends Component {

	handleSubmit(user) {
		const {handleDone, userID} = this.props;
		debugger;
		this.props.saveUserSettings(userID, user);
		handleDone();
	}

	render() {
		const {profile, handleDone} = this.props;
		return <EditForm
			formObject={{name:profile.name,nickname:profile.nickname}}
			handleSubmit={this.handleSubmit.bind(this)}
			handleCancel={handleDone}>
			<EditFormFields/>
		</EditForm>;
	}
}

const EditFormFields = (props) => {
	return <React.Fragment>
		<div>
			<label htmlFor="name">Name</label>
			<input type="text" id='name' required
					  defaultValue={props.formObject.name}
					  onChange={props.handleChange}/>
		</div>
		<div>
			<label htmlFor="nickname">Nickname</label>
			<input type="text" id='nickname'
				   defaultValue={props.formObject.nickname}
				   onChange={props.handleChange}/>
		</div>
	</React.Fragment>
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveUserSettings: (userID, user) => dispatch(saveUserSettings(userID, user))
	}
};

export default connect(null, mapDispatchToProps)(UserSettingsForm);