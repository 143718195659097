import firebase from 'firebase/app'

export const saveUserSettings = (userID, user) => {
	return (dispatch) => {
		const firestore = firebase.firestore();

		firestore.doc('users/' + userID).set(user, {merge: true}).then(() => {
			dispatch({ type: 'SAVE_USER_SETTINGS_SUCCESS' });
		}).catch(err => {
			dispatch({ type: 'SAVE_USER_SETTINGS_ERROR' }, err);
		});
	}
}