import React, {Component} from 'react';

class Modal extends Component {

    render() {
        const {show, header, content} = this.props;
        const modalStyles = {
            zIndex: 1003,
            display: (show ? "block" : "none")
        };
        const overlayStyles = {
            zIndex: 1002,
            display: "block",
            opacity: 0.5
        };
        const headerStyles = {
            color: "black"
        };
        const openClass = show ? "open" : "";
        return (
            <div>
                <div className={"modal " + openClass}  style={modalStyles}>
                    <div className="modal-content">
                        {header? <h4 style={headerStyles}>{header}</h4> : null}
                        {content || null}
                    </div>
                </div>
                {
                    show ? <div className="modal-overlay" style={overlayStyles}/> : null
                }
            </div>

        );
    }
}

export default Modal;