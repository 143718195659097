import { SET_CURRENT_CONTEST, SHOW_USER_SETTINGS } from '../actions/uiStateActions'

export const uiStateReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CURRENT_CONTEST:
            return {
                ...state,
                currentContest: action.payload
            };
        case SHOW_USER_SETTINGS:
            console.log("show user settings set to ", action.payload);
            return {
                ...state,
                showUserSettings: action.payload
            };
        default:
            return state;
    }
};