import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loading from "../layout/Loading";
import AdminQuestion from './AdminQuestion'
import {Redirect} from "react-router-dom";
import {saveCorrectChoices} from "../../store/actions/contestActions";

class AdminContest extends Component {

    componentDidMount() {
        let {match} = this.props;
        document.title = `Edit ${match.params.shortName}`;
    }

    handleChange = function(previousChoices, e) {
        this.setState({
            choices: {
                ...previousChoices,
                [e.target.name] : e.target.value
			}
        })
    }

    submitCorrectChoices = () => {
		const {match} = this.props;
		const shortName = match.params.shortName;
        this.props.saveCorrectChoices(shortName, this.state.choices, this.props.entries, this.props.contest.questions);
    }

    render() {
        const {contest, auth, profile} = this.props;
        if (auth.isLoaded && profile.isLoaded && !(auth.uid && profile.role === "admin")) return <Redirect to='/' />;
        return (
            contest ?
                <div className="container">
                    <div className="row">
                        <div className="col s12 m8 offset-m2 l6 offset-l3">
                            <h4>{contest.title}</h4>
                        </div>
                    </div>
					{ contest.questions && Object.keys(contest.questions).map(questionId => {
						return (
                            <AdminQuestion key={questionId} question={contest.questions[questionId]} handleChange={this.handleChange.bind(this, (this.state && this.state.choices))}/>
						)
					})}
					<div className="row">
                        <button onClick={this.submitCorrectChoices}>Submit</button>
                    </div>
                </div> : <Loading/>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        contest: state.firestore.data.contests && state.firestore.data.contests[ownProps.match.params.shortName],
        entries: state.firestore.data.contests && state.firestore.data.contests[ownProps.match.params.shortName] && state.firestore.data.contests[ownProps.match.params.shortName].entries,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveCorrectChoices: (contestShortName, choices, entries, questions) => dispatch(saveCorrectChoices(contestShortName, choices, entries, questions)),
	}
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => [
        'contests/' + props.match.params.shortName,
        'contests/' + props.match.params.shortName + "/entries",
    ]
    )
)(AdminContest)