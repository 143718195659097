import firebase from 'firebase/app'
import {SHOW_USER_SETTINGS} from "./uiStateActions";

export const sendMagicLink = (email) => {
    return (dispatch, getState) => {
        //https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
        //https://firebase.google.com/docs/auth/web/passing-state-in-email-actions#passing_statecontinue_url_in_email_actions
        const actionCodeSettings = {
            url: window.location.protocol + '//' + window.location.host + '/?finishSignup=true',
            handleCodeInApp:true
        };

        firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function() {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                dispatch({ type: 'MAGIC_LINK_SUCCESS'});
            })
            .catch(function(error) {
                // Some error occurred, you can inspect the code: error.code
                dispatch({ type: 'MAGIC_LINK_ERROR', error });
            });
    }
};

export const completeMagicLinkSignIn = () => {
    return (dispatch, getState) => {
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation.');
            }
            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then(function(result) {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    window.history.pushState(null, "", window.location.href.split("?")[0]);
                    console.log("dispatching show user settings");
                    dispatch({ type: SHOW_USER_SETTINGS, payload: true });
                })
                .catch(function(error) {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    dispatch({ type: 'MAGIC_LOGIN_ERROR', error });
                });
        }
    }
};


export const loginWithPassword = (credentials) => {
    return (dispatch, getState) => {
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err });
        });

    }
};

export const logout = () => {
    return (dispatch, getState) => {
        firebase.auth().signOut().then(() => {
            dispatch({ type: 'LOGOUT_SUCCESS' })
        });
    }
};