import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ContestList from "./ContestList";
import Loading from "../layout/Loading";

class Home extends Component {

    componentDidMount() {
        document.title = "Super Bowl Prop Bets!";
    }

	render() {
        const {contests} = this.props;
		const weekAgo = new Date();
		weekAgo.setDate(weekAgo.getDate() - 7);
		const fullyLoaded = contests && contests.reduce((acc, curr) => acc && typeof curr.dueDate !== "undefined", true);
		return (
			fullyLoaded ?
			<div className="container">
				<div className="row">
                    <div className="col s12 m8 offset-m2 l6 offset-l3">
						<h4>Current Contest</h4>
						<ContestList contests={contests.filter(contest => contest.dueDate.toDate() > weekAgo)} />
						<h4>Past Contests</h4>
						<ContestList contests={contests.filter(contest => contest.dueDate.toDate() < weekAgo)} />
					</div>
				</div>
			</div> : <Loading/>
		)
	}
}
const mapStateToProps = (state) => {
    return {
        contests: state.firestore.ordered.contests,
        auth: state.firebase.auth
    }
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'contests'}
    ])
)(Home)