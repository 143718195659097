const initState = {
    authError: null,
    magicLinkError: null
};

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
            console.log('login error', action);
            return {
                ...state,
                authError: 'Login failed'
            };
        case 'MAGIC_LINK_SUCCESS':
            console.log('magic link error', action);
            return {
                ...state,
                magicLinkStatus: 'Please check your email!'
            };
        case 'MAGIC_LINK_ERROR':
            console.log('magic link error', action);
            return {
                ...state,
                magicLinkStatus: 'Failed to send magic link.'
            };
        case 'MAGIC_LOGIN_ERROR':
            console.log('magic login error', action);
            return {
                ...state,
                magicLinkError: 'Failed to login from magic link.'
            };
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                ...state,
                authError: null
            };

        case 'LOGOUT_SUCCESS':
            console.log('logout success');
            return state;

        default:
            return state
    }
};

export default authReducer;