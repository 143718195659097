const initState = {

};

const contestReducer = (state = initState, action) => {
	switch(action.type){
		case "SAVE_CONTEST_ENTRY_SUCCESS": 
			return {
				...state,
				saveSuccess: true
			}
		case "SAVE_CONTEST_ENTRY_ERROR": 
		return {
			...state,
			saveSuccess: false
		}
		default:
			return state
	}
};

export default contestReducer;