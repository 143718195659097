import { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import {setCurrentContest} from "../../store/actions/uiStateActions";

class Contest extends Component {

    componentDidMount() {
        let {match} = this.props;
        document.title = `${match.params.shortName} Dashboard`;
    }

    componentWillUnmount() {
        this.props.setCurrentContest(null);
    }

    render() {
        const {contest} = this.props;
        const shortName = this.props.match.params.shortName;
        if (contest) {
            this.props.setCurrentContest({...contest, shortName:shortName});
        }
        return null
    }
}

const mapStateToProps = (state, ownProps) => {
    const contests = state.firestore.data.contests;
    const shortName = ownProps.match.params.shortName;
    const contest = contests ? contests[shortName] : null;

    return {
        contest: contest,
        auth: state.firebase.auth
    }
};

export default compose(
    connect(mapStateToProps, {setCurrentContest}),
    firestoreConnect((props) => [
        'contests/' + props.match.params.shortName
    ])
)(Contest)