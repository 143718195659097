import React from 'react'

const ContestSummary = ({contest}) => {
    return (
        <div className="card contest-summary">
            <div className="card-content grey-text text-darken-3">
                <span className="card-title ">{contest.title}</span>
            </div>
        </div>
    )
};

export default ContestSummary