import React, { Component } from 'react'
import RadioQuestion from "./RadioQuestion";
import CheckboxQuestion from "./CheckboxQuestion";

class EntryTable extends Component {

    render () {
        const {readonly, contest, entry, handleChange} = this.props;
        const questions = [];
        const pastDue = contest && contest.dueDate && new Date() > contest.dueDate.toDate();
        for(const [questionID, question] of Object.entries(contest.questions)) {
            const selectionMap = entry.selections[questionID];
            if(question.type === "radio") {
                questions.push(<RadioQuestion key={questionID} question={question} disabled={pastDue || readonly}
                                              selectionMap={selectionMap} handleChange={handleChange}/>)
            } else {
                questions.push(<CheckboxQuestion key={questionID} question={question} disabled={pastDue || readonly}
                                                 selectionMap={selectionMap} handleChange={handleChange}/>)
            }
        }

        return (
            <table>
                <tbody>
                    {questions}
                </tbody>
            </table>
        )
    }

}

export default EntryTable;