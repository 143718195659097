import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loading from "../layout/Loading";
import { Redirect } from 'react-router-dom'
import ContestList from "../home/ContestList";
import Modal from "../layout/Modal";
import AddContestForm from "./forms/AddContestForm";
import ImportContestForm from "./forms/ImportContestForm";
import ImportResultsForm from "./forms/ImportResultsForm";


class Admin extends Component {

    state = {
        showModal: false,
        modalHeader: "",
        modalForm: null
    };

    closeModal() {
        this.setState({
            showModal: false
        });
    }

    componentDidMount() {
        document.title = "Super Bowl Prop Bets!";
    }

    addContest = () => {
        this.setState({
            showModal: true,
            modalHeader: "Add Contest",
            modalForm: <AddContestForm handleDone={this.closeModal.bind(this)}/>
        });
    };

    importContest = () => {
        this.setState({
            showModal: true,
            modalHeader: "Import Contest",
            modalForm: <ImportContestForm handleDone={this.closeModal.bind(this)}/>
        });
    };

    importContestResults = () => {
        this.setState({
            showModal: true,
            modalHeader: "Import Contest Results",
            modalForm: <ImportResultsForm handleDone={this.closeModal.bind(this)}/>
        });
    };

    render() {
        const {contests, auth, profile} = this.props;
        if (auth.isLoaded && profile.isLoaded && !(auth.uid && profile.role === "admin")) return <Redirect to='/' />;
        return (
            contests ?
                <div className="container">
                    <Modal
                        show={this.state.showModal}
                        header={this.state.modalHeader}
                        content={this.state.modalForm}
                    />
                    <div className="row">
                        <div className="col s12 m3">
                            <h4>Add Contest</h4>
                            <button className="btn green modal-trigger adminButton" onClick={this.addContest}>
                                <i className="material-icons left">add</i>Add Contest
                            </button>
                            <br/>
                            <button className="btn green adminButton" onClick={this.importContest}>
                                <i className="material-icons left">import_export</i>Import Contest
                            </button>
                            <br/>
                            <button className="btn green adminButton" onClick={this.importContestResults}>
                                <i className="material-icons left">import_export</i>Import Results
                            </button>
                        </div>
                        <div className="col s12 m9">
                            <h4>Existing Contests</h4>
                            <ContestList contextPath='admin/' contests={contests} />
                        </div>
                    </div>
                </div> : <Loading/>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        contests: state.firestore.ordered.contests,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'contests'}
    ])
)(Admin)