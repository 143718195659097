import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Header from "./components/layout/Header";
import Login from "./components/auth/Login";
import Home from "./components/home/Home";
import Contest from "./components/contest/Contest";
import Admin from "./components/admin/Admin";
import AdminContest from "./components/admin/AdminContest";
import Leaderboard from "./components/contest/leaderboard/Leaderboard";
import EntryForm from "./components/contest/EntryForm";
import Chart from "./components/contest/Chart";


class App extends Component {
  render() {
    return (
        <Router>
          <div className="App">
              <Route component={Header}/>

              <Switch>
				  <Route exact path="/" component={Home} />
                  <Route path="/login" component={Login} />
                  <Route exact path="/admin" component={Admin} />
                  <Route path="/admin/:shortName" component={AdminContest} />
                  <Route path="/:shortName" component={Contest} />
              </Switch>

              <Switch>
                  <Route exact path="/:shortName/leaderboard" component={Leaderboard} />
                  <Route exact path="/:shortName/entryform" component={EntryForm} />
                  <Route exact path="/:shortName/chart" component={Chart} />
              </Switch>
          </div>
        </Router>
    );
  }
}

export default App;
