import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginWithPassword, sendMagicLink } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class Login extends Component {

    state = {
        emailOnly: '',
        email: '',
        password: ''
    };

    componentDidMount() {
        document.title = "Login";
    }

    handleChange(e){
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmitLogin(e){
        e.preventDefault();
        this.props.sendMagicLink(this.state.emailOnly);
    }

    handleSubmitWithPassword(e){
        e.preventDefault();
        this.props.loginWithPassword({email: this.state.email, password:this.state.password});
    }

    render() {
        const { magicLinkStatus, authError, auth } = this.props;
        if (auth.uid) return <Redirect to='/' />;
        return (
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <div className="card-panel">
                            <form className="white" onSubmit={this.handleSubmitLogin.bind(this)}>
                                <h5 className="grey-text text-darken-3">Login via Email</h5>
                                <div className="input-field">
                                    <label htmlFor="emailOnly">Email</label>
                                    <input type="email" id='emailOnly' onChange={this.handleChange.bind(this)} />
                                </div>
                                <div className="input-field">
                                    <button className="btn pink lighten-1 z-depth-0">Send Login Link</button>
                                    <div className="center red-text">
                                        { magicLinkStatus ? <p>{magicLinkStatus}</p> : null }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 offset-m3">
                        <div className="card-panel">
                            <form className="white" onSubmit={this.handleSubmitWithPassword.bind(this)}>
                                <h5 className="grey-text">Login with Password</h5>
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id='email' onChange={this.handleChange.bind(this)} />
                                </div>
                                <div className="input-field">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id='password' onChange={this.handleChange.bind(this)} />
                                </div>
                                <div className="input-field">
                                    <button className="btn pink lighten-1 z-depth-0">Login</button>
                                    <span className="right">
                                        { authError ? <p className="red-text">{authError}</p> : <p className="grey-text">Used by admins only.</p> }
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return{
        authError: state.auth.authError,
        magicLinkStatus: state.auth.magicLinkStatus,
        auth: state.firebase.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendMagicLink: (email) => dispatch(sendMagicLink(email)),
        loginWithPassword: (creds) => dispatch(loginWithPassword(creds))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
