import React from 'react'

const AdminQuestion = ({question, handleChange}) => {
    return (
        <div>
            <span>{question.text}</span>
            <select className="browser-default" name={question.questionID} defaultValue={question.correctChoiceID} onChange={handleChange}>
                <option value="-1">none</option>
				{Object.keys(question.choices).map(choiceId => {
					return (
                        <option key={choiceId} value={choiceId}>{question.choices[choiceId].text}</option>
					);
				})}
            </select>
        </div>
    )
};

export default AdminQuestion