import React, { Component } from 'react'

class LeaderboardEntryCell extends Component {

    render() {
        let retVal = this.props.choices.map(choice => <span key={choice.choiceID} className={(choice.resolvedState === 1) ? "rightAnswer" : ((choice.resolvedState === 2) ? "wrongAnswer" : "")}>{choice.shortText + (choice.betAmount > 0 ? (" ($" + choice.betAmount + ") ") : "")}</span>);
        return retVal;
    }
}

export default LeaderboardEntryCell