import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from 'react-redux';
import Modal from './Modal'
import UserSettingsForm from '../auth/UserSettingsForm'
import {completeMagicLinkSignIn} from "../../store/actions/authActions";
import {showUserSettings} from "../../store/actions/uiStateActions";

class Header extends Component {

	state = {
		showModal: false,
		showTime: 0,
		countdownText: ""
	};

	componentDidMount() {
		if(this.props.location.search.includes("finishSignup")) {
			this.props.completeMagicLinkSignIn();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {contest, shouldShowSettings, profile} = this.props;

		if (contest && contest.dueDate) {
			if(!this.timer) {
				this.timer = setInterval(() =>
				{
					const now = new Date();
					const then = contest.dueDate.toDate();
					let countdownText = "";
					if (then > now) {
						const diff = then - now;
						countdownText = "Contest starts in: "+millisecondsToString(diff);
					}
					else {
						countdownText = "Contest has started!"
						clearInterval(this.timer);
						this.timer = null;
					}
					this.setState({
						countdownText: countdownText
					})
				}, 500);
			}
		} else {
			clearInterval(this.timer);
			this.timer = null;
		}
		if(shouldShowSettings && profile && profile.isLoaded) {
			this.props.showUserSettings(false);
			this.openUserSettings();
		}
		if(!prevProps.profile.isLoaded && this.props.profile.isLoaded && this.state.showModal) {
			this.openUserSettings();
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	openUserSettings = () => {
		const {auth, profile} = this.props;
		this.setState({
			showTime: "" + new Date().getTime() +  profile.name,
			showModal: true,
			modalHeader: "User Settings",
			modalForm: <UserSettingsForm userID={auth.uid} profile={profile} handleDone={this.closeModal.bind(this)}/>
		});
	};

	closeModal() {
		this.setState({
			showModal: false
		});
	};

	render () {
		const {auth, profile, contest} = this.props;
		const links = auth.uid ? <SignedInLinks profile={profile} editUserSettings={this.openUserSettings.bind(this)}/> : <SignedOutLinks/>;
		const currentPath = this.props.location.pathname;


		return (
			<nav className={"nav-extended header " + (window.location.href.includes("admin") ? "admin" : null)}>
				<div className="top-bar nav-wrapper">
					<Link to={''} className="homeLink">Home</Link>
					{
						contest ? (
						<div className="title-container center brand-logo hide-on-small-only">
							<div className="contest-title">{contest.title}</div>
							<div className="contest-subtitle">{this.state.countdownText}</div>
						</div>
						) : null
					}
					<div className="right profileLinks">
						{links}
					</div>
				</div>
				{
					contest ? (
						<div className="nav-wrapper hide-on-med-and-up">
							<div className="title-container center">
								<div className="contest-title">{contest.title}</div>
								<div className="contest-subtitle">{this.state.countdownText}</div>
							</div>
						</div>
					) : null
				}
				{
					contest ? (
						<div className="nav-content">
							<ul className="tabs tabs-transparent">
								<li className={"tab col s3" + (currentPath.includes("entryform") ? " active" : "")}>
									<Link to={'/' + contest.shortName + '/entryform'}>Entry Form</Link>
								</li>
								<li className={"tab col s3" + (currentPath.includes("leaderboard") ? " active" : "")}>
									<Link to={'/' + contest.shortName + '/leaderboard'}>Leaderboard</Link>
								</li>
								<li className={"tab col s3" + (currentPath.includes("chart") ? " active" : "")}>
									<Link to={'/' + contest.shortName + '/chart'}>Chart</Link>
								</li>
							</ul>
						</div>
					) : null
				}

				<Modal
					key={this.state.showTime}
					show={this.state.showModal}
					header={this.state.modalHeader}
					content={this.state.modalForm}
				/>
			</nav>
		)
	}
}

const mapStateToProps = (state) =>
{
    return{
        auth: state.firebase.auth,
        profile: state.firebase.profile,
		contest: state.uiState.currentContest,
		shouldShowSettings : state.uiState.showUserSettings
    }
};


const mapDispatchToProps = (dispatch) => {
	return {
		completeMagicLinkSignIn: () => dispatch(completeMagicLinkSignIn()),
		showUserSettings: (show) => dispatch(showUserSettings(show))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)



const millisecondsToString = (milliseconds) => {
	const seconds = Math.floor(milliseconds / 1000);
	const numdays = Math.floor(seconds / 86400);
	const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
	const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
	const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

	let str = addIfNonZero(numdays, "d", "");
	str += addIfNonZero(numhours, "h", str);
	str += addIfNonZero(numminutes, "m", str);
	str += addIfNonZero(numseconds, "s", str);

	return str;
};

const addIfNonZero = (number, label, existingString) => {
	if (existingString.length === 0) {
		return (number > 0 ? number + label + " " : "");
	}
	else {
		return (number + label + " ");
	}
};