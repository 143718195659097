import React, {Component} from 'react';
import moment from "moment/moment";
import {connect} from "react-redux";
import EditForm from "../../layout/EditForm";
import {saveContest} from "../../../store/actions/adminActions";

class AddContestForm extends Component {

    getFormObject() {
        return {
            // Defaults
            title: "", instructions: "", shortName:"",
            dueDate: new Date()
        }
    }

    handleSubmit(contestFields) {
        const {saveContest, handleDone} = this.props;
        const shortName = contestFields.shortName;
        delete(contestFields[shortName]);
        saveContest(contestFields.shortName,contestFields);
        handleDone();
    };

    handleCancel() {
        const {handleDone} = this.props;
        handleDone();
    }

    render() {
        const {itemID} = this.props;
        return <EditForm
            formObject={this.getFormObject()}
            handleSubmit={this.handleSubmit.bind(this)}
            handleCancel={this.handleCancel.bind(this)}
            handleDelete={itemID? this.handleDelete.bind(this) : null}>
            <EditFormFields/>
        </EditForm>;
    }
}

const EditFormFields = (props) => {
    return <React.Fragment>
        <div>
            <label htmlFor="title">Title</label>
            <input required type="text" id='title'
                   value={props.formObject.title}
                   onChange={props.handleChange}/>
        </div>
        <div>
            <label htmlFor="instructions">Instructions</label>
            <textarea id='instructions'
                      value={props.formObject.instructions}
                      onChange={props.handleChange}/>
        </div>
        <div>
            <label htmlFor="dueDate">Due Date</label>
            <input required type="date" id='dueDate'
                   value={moment(props.formObject.dueDate).format("YYYY-MM-DD")}
                   onChange={props.handleChange}/>
        </div>
        <div>
            <label htmlFor="shortName">Short Name</label>
            <input type="text" id='shortName'
                   value={props.formObject.shortName}
                   onChange={props.handleChange}/>
        </div>
    </React.Fragment>
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveContest: (shortName, contest) => dispatch(saveContest(shortName, contest)),
    }
};

export default connect(null, mapDispatchToProps)(AddContestForm);