import firebase from 'firebase/app';

export const saveContest = (shortName, contest) => {
    return (dispatch, getState) => {
        const firestore = firebase.firestore();
        firestore.doc('contests/' + shortName).set(contest).then(() => {
            dispatch({ type: 'SAVE_CONTEST_SUCCESS' });
        }).catch(err => {
            dispatch({ type: 'SAVE_CONTEST_ERROR' }, err);
        });
    }
};


export const importContestResults = (shortName, contestResults) => {
    return (dispatch, getState) => {
        const firestore = firebase.firestore();
        const batch = firestore.batch();

        for(const entry of contestResults.entries) {
            const userEntryDoc = firestore.doc('contests/' + shortName + '/entries/' + entry.userID);
            batch.set(userEntryDoc,entry);
        }

        /*for(const [userID, user] of Object.entries(contestResults.users)) {
            const userDoc = firestore.doc('users/' + userID);
            batch.set(userDoc, user);
        }*/

        const entryUserIDsDoc = firestore.doc('contests/' + shortName );
        batch.set(entryUserIDsDoc, {
            entryUserIDs: contestResults.users
        }, { merge: true });

        batch.commit().then(() => {
            dispatch({ type: 'IMPORT_CONTEST_RESULTS_SUCCESS' });
        }).catch(err => {
            dispatch({ type: 'IMPORT_CONTEST_RESULTS_ERROR' }, err);
        });
    }
};