import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loading from "../layout/Loading";
import EntryTable from "./entry/EntryTable";
import {saveContestEntry} from "../../store/actions/contestActions";
import Instructions from "./Instructions";
import Modal from '../layout/Modal'

class EntryForm extends Component {

    state = {
        showModal: false,
        entry : {
            selections: {}
        },
        isNewEntry: true,
        moneySpent: 0,
        displaySaveStatus: false,
        invalidEntries: {}
    };

    componentDidMount() {
        const {match} = this.props;
        document.title = `${match.params.shortName} Entry Form`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.isNewEntry && this.props.entry) {
            this.setState({
                entry: {
                    ...this.props.entry,
                },
                moneySpent : calculateMoneySpent(this.props.entry.selections),
                isNewEntry: false
            })
        }
    }

    openInstructions = () => {
        this.setState({
            showModal: true,
            modalHeader: "Instructions",
            modalForm: <Instructions handleDone={this.closeModal.bind(this)}/>
        });
    };

    closeModal() {
        this.setState({
            showModal: false
        });
    };

    handleChange = (questionID, questionObject) => {
        //make a copy of the state
        let newState = {
            entry : {
                ...this.state.entry
            },
            invalidEntries : {
                ...this.state.invalidEntries
            }
        };

        newState.entry.selections = {
            ...newState.entry.selections,
            [questionID] : questionObject
        };

        newState["moneySpent"] = calculateMoneySpent(newState.entry.selections);

        let maxBet = 0;
        let minBet = 0;
        if (this.props.contest.questions[questionID].type === "radio") {
            maxBet = 30;
            minBet = 5;
        } else if (this.props.contest.questions[questionID].type === "multi") {
            maxBet = 5;
            minBet = 1
        }
        let invalidEntry = false;
        Object.values(questionObject).forEach((selection) => {
            if (selection.betAmount < minBet || selection.betAmount > maxBet ) {
                invalidEntry = true;
            }
        });
        if (invalidEntry) {
            newState.invalidEntries[questionID] = true;
        } else {
            delete newState.invalidEntries[questionID];
        }

        this.setState(newState);

    };

    handleSubmit = () => {
        this.setState({
            displaySaveStatus: true
        })
        this.props.saveContestEntry(this.props.match.params.shortName, this.props.auth.uid, this.state.entry, this.props.contest.questions);
    };



    render() {
        const {contest, auth} = this.props;
        const moneyRemaining = this.state.entry ? 100 - this.state.moneySpent : 100;
        const moneyRemainingString = moneyRemaining < 0 ? "-$" + Math.abs(moneyRemaining) : "$"+moneyRemaining;
        const pastDue = contest && contest.dueDate ? new Date() > contest.dueDate.toDate() : false;
        let allQuestionsAnswered = true;
        if (this.state.entry && this.state.entry.selections && contest && contest.questions) {
            allQuestionsAnswered = (Object.keys(this.state.entry.selections).length === Object.keys(contest.questions).length);
            if (allQuestionsAnswered) {
                allQuestionsAnswered = Object.values(this.state.entry.selections).reduce( (accumulator, currentValue) => {
                    return accumulator && Object.keys(currentValue).length > 0 ;
                }, true)
            }
        }
        const submitDisabled = pastDue || moneyRemaining !== 0 || !allQuestionsAnswered || Object.keys(this.state.invalidEntries).length > 0;
        if ((this.props.saveSuccess === true || this.props.saveSuccess === false) && this.state.displaySaveStatus) {
            setTimeout(function() {
                this.setState({
                    displaySaveStatus: false
                })
            }.bind(this), 3000);
        }
        return (
            (contest && contest.questions) ?
                <div className="container">
                    <Modal
                        show={this.state.showModal}
                        header={this.state.modalHeader}
                        content={this.state.modalForm}
                    />
                    <div className="row entry-form-container">
                        <div className="col s12 m8 offset-m2 l6 offset-l3">
                            <br/>
                            <div>{contest.instructions} <button className="btn-flat help-btn" onClick={this.openInstructions}><i className="tiny material-icons">help_outline</i></button></div>
                            <div hidden={auth.uid} className="invalidBackground center">Login to fill out the form.</div>
                            <EntryTable readonly={!auth.uid} contest={contest} entry={this.state.entry} handleChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row alwaysAtBottom">
                        <span className={moneyRemaining === 0 ? "validBackground moneyRemaining" : "invalidBackground moneyRemaining"}>Money remaining: {moneyRemainingString}</span>
                        {(this.props.saveSuccess === true) && this.state.displaySaveStatus && <span id="saveStatus" className="validBackground moneyRemaining">Entry Submitted</span>}
                        {(this.props.saveSuccess === false) && this.state.displaySaveStatus && <span id="saveStatus" className="invalidBackground moneyRemaining">Entry Submitted</span>}
                        <button className="btn" disabled={submitDisabled} onClick={this.handleSubmit}>Submit</button>
                    </div>

                </div> : <Loading/>
        )
    }
}
const mapStateToProps = (state, props) =>
{
    const shortName = props.match.params.shortName;
    const contests = state.firestore.data.contests;
    const contest = contests ? contests[shortName] : null;
    const auth = state.firebase.auth;
    const entry = contest && contest.entries && auth ? contest.entries[auth.uid] : null;
    return {
        contest: contest,
        entry: entry,
        auth: auth,
        saveSuccess: state.contest.saveSuccess
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveContestEntry: (contest, userID, entry, questions) => dispatch(saveContestEntry(contest, userID, entry, questions)),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => [
        'contests/' + props.match.params.shortName,
        {
            collection: 'contests',
            doc: props.match.params.shortName,
            subcollections: [{
                collection: 'entries',
                doc: props.auth.uid
            }]
        }
    ])
)(EntryForm)

const calculateMoneySpent = (questions) => {
    let moneySpent = 0;
    if (questions) {
        for (const questionID of Object.keys(questions)) {
            const selections = questions[questionID];
            if (selections) {
                for (const choiceID of Object.keys(selections)) {
                    const amount = parseInt(selections[choiceID].betAmount);
                    moneySpent += isNaN(amount) || parseInt(choiceID) === -1 ? 0 : amount;
                }
            }
        }
    }
    return moneySpent;
}