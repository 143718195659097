import React from 'react'

const Loading = (props) => {
    return (
        <div className="container center">
            <p>Loading...</p>
        </div>
    )
};

export default Loading;