import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Loading from "../../layout/Loading";
import MUIDataTable from "mui-datatables";
import LeaderboardEntryCell from "./LeaderboardEntryCell";
import LeaderboardNameCell from "./LeaderboardNameCell";

class Leaderboard extends Component {

    componentDidMount() {
        let {match} = this.props;
        document.title = `${match.params.shortName} Leaderboard`;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const retVal = this.areEqual(this.props.auth, nextProps.auth) && this.areEqual(this.props.contest, nextProps.contest) && this.areEqual(this.props.users, nextProps.users) && this.areEqual(this.props.entries, nextProps.entries);
        return !retVal;
    }

    areEqual(value, other) {

        // Get the value type
        var type = Object.prototype.toString.call(value);
    
        // If the two objects are not the same type, return false
        if (type !== Object.prototype.toString.call(other)) return false;
    
        // If items are not an object or array, return false
        if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
    
        // Compare the length of the length of the two items
        var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
        var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
        if (valueLen !== otherLen) return false;
    
        // Compare two items
        var compare = function (item1, item2) {
    
            // Get the object type
            var itemType = Object.prototype.toString.call(item1);
    
            // If an object or array, compare recursively
            if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
                if (!this.areEqual(item1, item2)) return false;
            }
    
            // Otherwise, do a simple comparison
            else {
    
                // If the two items are not the same type, return false
                if (itemType !== Object.prototype.toString.call(item2)) return false;
    
                // Else if it's a function, convert to a string and compare
                // Otherwise, just compare
                if (itemType === '[object Function]') {
                    if (item1.toString() !== item2.toString()) return false;
                } else {
                    if (item1 !== item2) return false;
                }
    
            }
        }.bind(this);
    
        // Compare properties
        if (type === '[object Array]') {
            for (var i = 0; i < valueLen; i++) {
                if (compare(value[i], other[i]) === false) return false;
            }
        } else {
            for (var key in value) {
                if (value.hasOwnProperty(key)) {
                    if (compare(value[key], other[key]) === false) return false;
                }
            }
        }
    
        // If nothing failed, return true
        return true;
    
    }

    render() {
        const {auth, contest, users, entries} = this.props;
        let questions = {};
        let columns;
        let data = [];
        const hiddenValue = [
            {
                betAmount : 0,
                choiceID : -1,
                resolvedState : 0,
                shortText : "---"
            }
        ];
        const noValue = [
            {
                betAmount : 0,
                choiceID : -1,
                resolvedState : 0,
                shortText : ""
            }
        ];
        let currentUserNickname;
        if (contest && contest.questions && users) {
            // turn questions object into an array
            questions = Object.values(contest.questions);
            // sort questions by ID.  this way questions are in a deterministic order when displayed
            questions.sort(function(a,b) {
                return a.questionID - b.questionID;
            })
            // map question short text to the column titles
            columns = questions.map((x, index, originalArray) => { 
                return { 
                    name: x.shortText,
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                            <LeaderboardEntryCell
                                choices={value}
                            />
                            );
                        },
                        sort: (index < originalArray.length-2) //do not allow sort for the last two columns, which is always first TD scored for each team
                    }
                }
                });
            // get an array of questionIDs    
            let columnQuestionIDs = questions.map(x => x.questionID);
            // add in static columns
            const nameColumn = {
                name: "Name",
                options: {
                    customBodyRender: (value) => {
                        return (<LeaderboardNameCell nickname={value.nickname} name={value.name} paid={value.paid} />);
                    }
                }
            };
            columns.splice(0, 0, nameColumn, "Actual Winnings", "Potential Winnings");
            if (entries) {
                // sort entries by userID so that we have an deterministic order when initially displayed
                let entriesArray = Object.values(entries);
                entriesArray.sort(function(a,b) {
                    return a.userID - b.userID;
                });
    
                // iterate over user entries
                for (let x = 0; x < entriesArray.length; x++) {
                    let userEntry = entriesArray[x];
                    if(!userEntry) {
                        continue;
                    }
                    const userProfile = users[userEntry.userID];
                    if (userEntry && userProfile) {
                        const userNickname = userProfile.nickname || userProfile.name;
                        const userName = userProfile.name;
                        if (auth && auth.uid && (auth.uid.toString() === userEntry.userID.toString())) {
                            currentUserNickname = userNickname
                        }
                        // populate initial rows with static data
                        let userRow = [{nickname: userNickname, name: userName, paid: userEntry.paid || false}, (userEntry.actualWinnings || "0"), (userEntry.potentialWinnings || "0")];
                        // iterate over individual entry to pull out response and value of bet
                        let userEntryMap = {};
                        const userEntrySelectionKeys = Object.keys(userEntry.selections);
                        if (userEntrySelectionKeys) {
                            for (let y = 0; y < userEntrySelectionKeys.length; y++) {
                                const questionID = userEntrySelectionKeys[y];
                                if (userEntry.selections[questionID]) {
                                    const choiceIDs = Object.keys(userEntry.selections[questionID]);
                                    if (choiceIDs) {
                                        for (let z = 0; z < choiceIDs.length; z++) {
                                            const choiceID = choiceIDs[z];
                                            let userChoice = Object.assign({}, userEntry.selections[questionID][choiceID]);
                                            const contestQuestion = contest.questions[questionID];
                                            if (contestQuestion && contestQuestion.choices && contestQuestion.choices[choiceID]) {
                                                userChoice.shortText = contestQuestion.choices[choiceID].shortText;
                                                userEntryMap[questionID] = userEntryMap[questionID] || [];
                                                userEntryMap[questionID].push(userChoice);
                                            }
                                        }
                                    }
                                }
                            }
                            // push responses into user's row data in the order of the columns
                            for (let z = 0; z < columnQuestionIDs.length; z++) {
                                if (userEntryMap[columnQuestionIDs[z]]) {
                                    userRow.push(userEntryMap[columnQuestionIDs[z]]);
                                } else {
                                    userRow.push(noValue);
                                }
                            }
                            data = data || [];
                            data.push(userRow);
                        }
                    }
                }

                // iterate over entryUserIDs and display hidden values for any user we did not have access to
                if (contest.entryUserIDs) {
                    for (const userID of contest.entryUserIDs) {
                        if (!entries[userID] && users[userID]) {
                            let hiddenValuesRowData = [{nickname: users[userID].nickname, name:users[userID].name, paid:users[userID].paid || false}, "---", "---"];
                            for (let y = 0; y < columns.length-3; y++) {
                                hiddenValuesRowData.push(hiddenValue);
                            }
                            data.push(hiddenValuesRowData);
                        }
                    }
                }
            }

        } 

        const options = {
            filter: false,
            search: false,
            download: false,
            selectableRows: false,
            elevation: 0,
            pagination: false,
            responsive: 'stacked',
            rowsPerPage: 40,
            customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                    if (colIndex === 0) {
                        return ((a.data[colIndex].nickname.toLowerCase() > b.data[colIndex].nickname.toLowerCase()) ? 1 : -1) * (order === "asc" ? -1 : 1);
                    } else if (colIndex <= 2) {
                        // data is a single value
                        return (a.data[colIndex] - b.data[colIndex]) * (order === "asc" ? -1 : 1);
                    } else {
                        // data is an array(with just one value).  Multi-select props are not sortable(yet)
                        if (a.data[colIndex][0].choiceID === b.data[colIndex][0].choiceID) {
                            return b.data[colIndex][0].betAmount - a.data[colIndex][0].betAmount;
                        } else {
                            return (a.data[colIndex][0].choiceID - b.data[colIndex][0].choiceID) * (order === "asc" ? -1 : 1);
                        }
                    }
                });
            },
            setRowProps: (row, rowIndex) => {
                let props = {};
                if (row[0].props.nickname === currentUserNickname) {
                    props.currentuser = "true";
                }
                if (rowIndex % 2 === 0) {
                    props.striped = "true"
                }
                return props;
            }
        };


        return (
            
                    <>
                    <div className="row leaderboardContainer">

                    {(contest && columns && data) ?
                                <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                                /> : <Loading/> }
                            </div> 
                        </> 
                        
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        contest: state.firestore.data.contests && state.firestore.data.contests[ownProps.match.params.shortName],
        entries: state.firestore.data['contests/' + ownProps.match.params.shortName + '/entries'],
        users: state.firestore.data.users,
        auth: state.firebase.auth
    }
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        let collections = [];
        collections.push({
            collection: 'contests',
            doc: props.match.params.shortName,
        });
        collections.push({
            collection: 'users',
        });
        collections.push({
            collection: 'contests/' + props.match.params.shortName + '/entries',
            doc: props.auth.uid
        });
        collections.push({
            collection: 'contests/' + props.match.params.shortName + '/entries',
        });
        return collections})
)(Leaderboard)