import authReducer from './authReducer';
import userSettingsReducer from './userSettingsReducer';
import contestReducer from './contestReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { uiStateReducer } from "./uiStateReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    userSettings: userSettingsReducer,
	contest: contestReducer,
    uiState: uiStateReducer
});

export default rootReducer