import React from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'

const SignedInLinks = (props) => {
    const profile =  props.profile;
    const adminLink = (profile.isLoaded && profile.role === "admin") ?
        <li><Link to='/admin'>Admin</Link></li> :
        null;
    return (
        <ul>
            {adminLink}
            <li><button className="btn-flat" onClick={props.logout}>Log Out</button></li>
            <li>
                <button className="btn-floating blue lighten-1 center-align" onClick={props.editUserSettings}>
                    {props.profile.name ? props.profile.name.charAt(0) : <i className="material-icons">person</i>}
                </button>
            </li>
        </ul>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(null, mapDispatchToProps)(SignedInLinks)