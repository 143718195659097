import React from 'react'
import ContestSummary from './ContestSummary'
import { Link } from 'react-router-dom'

const ContestList = ({contests, contextPath}) => {
    const route = contextPath ? contextPath : '';
    return (
        <div className="contest-list section">
            { contests && contests.map(contest => {
                return (
                    <Link to={route + contest.id + '/entryform'} key={contest.id}>
                        <ContestSummary contest={contest}/>
                    </Link>
                )
            })}
        </div>
    )
};

export default ContestList