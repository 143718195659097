import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

class LeaderboardNameCell extends Component {

    render() {
        return (
            <span className="valign-wrapper">
                <i data-tip={this.props.name} className="tiny material-icons userIcon">person</i>
                {this.props.nickname}
                {this.props.paid ? <i className="tiny material-icons">attach_money</i> : null}
                <ReactTooltip />
            </span>
            );

    }
}

export default LeaderboardNameCell