export const SET_CURRENT_CONTEST = 'SET_CURRENT_CONTEST';
export const SHOW_USER_SETTINGS = 'SHOW_USER_SETTINGS';

export const setCurrentContest = (contest) => ({
    type: SET_CURRENT_CONTEST,
    payload: contest
});

export const showUserSettings = (show) => ({
    type: SHOW_USER_SETTINGS,
    payload: show
});